<template>
  <div class="login-container"></div>
</template>

<script>
import settings from "@/settings";

export default {
  name: "Login",
  components: {},
  data() {},
  created() {
    const query = this.$route.query;
    if (query) {
      window.location =
        settings.service_login +
        "?auth_return=" +
        encodeURIComponent(settings.auth_redirect);
    }
  }
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
}
</style>